<script lang="ts">
	export let percentage: number = 100;
	export let color = '#836EEB';
	export let bgColor = '#E5E5E5';
	export let strokeColor = 'transparent';
	export let uuid = Math.random().toString(36).substring(7);
	export let width: number = 15;
	export let height: number = 15;

	let maskId = `star-mask-${uuid}`;
	$: maskWidth = (percentage / 100) * 15;
</script>

<svg {width} {height} viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
	<mask id={maskId} maskUnits="userSpaceOnUse">
		<rect x="0" y="0" width={maskWidth} height="15" fill="white" />
	</mask>
	<path
		d="M8.01663 0.31497C7.91916 0.122014 7.71902 0 7.5 0C7.28097 0 7.08084 0.122014 6.98336 0.31497L4.97871 4.28319L0.494543 4.91976C0.276733 4.95068 0.0957744 5.10081 0.0280315 5.30679C-0.0397113 5.51278 0.0175625 5.73874 0.175684 5.88931L3.41805 8.97698L2.65281 13.3358C2.61546 13.5485 2.70401 13.7637 2.88127 13.8909C3.05852 14.0182 3.2938 14.0355 3.48831 13.9356L7.5 11.8751L11.5117 13.9356C11.7062 14.0355 11.9415 14.0182 12.1187 13.8909C12.296 13.7637 12.3845 13.5485 12.3472 13.3358L11.5819 8.97698L14.8243 5.88931C14.9824 5.73874 15.0397 5.51278 14.972 5.30679C14.9042 5.10081 14.7233 4.95068 14.5055 4.91976L10.0213 4.28319L8.01663 0.31497Z"
		fill={bgColor}
		stroke={strokeColor}
		stroke-width="0.2"
	/>
	<path
		d="M8.01663 0.31497C7.91916 0.122014 7.71902 0 7.5 0C7.28097 0 7.08084 0.122014 6.98336 0.31497L4.97871 4.28319L0.494543 4.91976C0.276733 4.95068 0.0957744 5.10081 0.0280315 5.30679C-0.0397113 5.51278 0.0175625 5.73874 0.175684 5.88931L3.41805 8.97698L2.65281 13.3358C2.61546 13.5485 2.70401 13.7637 2.88127 13.8909C3.05852 14.0182 3.2938 14.0355 3.48831 13.9356L7.5 11.8751L11.5117 13.9356C11.7062 14.0355 11.9415 14.0182 12.1187 13.8909C12.296 13.7637 12.3845 13.5485 12.3472 13.3358L11.5819 8.97698L14.8243 5.88931C14.9824 5.73874 15.0397 5.51278 14.972 5.30679C14.9042 5.10081 14.7233 4.95068 14.5055 4.91976L10.0213 4.28319L8.01663 0.31497Z"
		fill={color}
		stroke={strokeColor}
		stroke-width="0.2"
		mask={`url(#${maskId})`}
	/>
</svg>
